<template>
  <div id="app">
    <HeaderComponent v-if="$route.path !== '/login'" />
    <router-view />
  </div>
</template>
<script>
import HeaderComponent from "./components/Headers/HeaderComponent.vue";
export default {
  components: {
    HeaderComponent,
  },
};
</script>
<style>
:root {
  --main-color: #20294b;
  --main-color2: #8699a9;
  --main-color3: #a92020;
  --main-color4: rgba(169, 32, 32, 0.6);
  --main-color5: #e5e8eb; /* //background color */
  --main-color6: #f1f5f7;
  --main-color7: #fafad2;
  --main-color8: #f7b538;
  --main-color9: #ff8c42;
  --main-color10: rgb(85 98 40);
}

body {
  background-color: var(--main-color5);
  margin: 0;
}

body.sgi-page {
  height: 100%;
  overflow: hidden;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #274a6c;
  background-color: var(--main-color5);
  font-size: 15px;
}

.header-cover {
  padding-top: 4rem;
}
nav {
  width: 95%;
  justify-content: center;
  display: flex;
}
nav a {
  font-weight: bold;
  color: #2c3e50;
}
nav a.router-link-active {
  color: var(--main-color7);
  text-decoration: underline;
}
a:-webkit-any-link {
  color: -webkit-link;
  cursor: pointer;
  text-decoration: none;
  /* color: #274a6c; */
  /* color: #b5d4bf; */
  color: #efd3c9;
}
.icon {
  width: 40px;
}
.text-align-right {
  text-align: right;
}
.text-align-left {
  text-align: left;
}
/* Generic Styles */
table td {
  overflow: hidden;
  text-overflow: ellipsis;
}
.data-table tbody tr:hover {
  background-color: #f1f1f1;
}
ul {
  margin-block-start: 10px;
  margin-block-end: 10px;
}
img {
  width: 100%;
}
button {
  cursor: pointer;
  padding: 0.5rem 1rem;
  font-size: 14px;
  font-weight: 600;
  border: 1px solid var(--main-color3);
  color: #fff;
  background-color: var(--main-color3);
  width: fit-content;
  border-radius: 5px;
  height: fit-content;
  margin: auto;
}
button:hover {
  background-color: #fff;
  color: #ff6b6b;
}
/* Data Table Styles */
.data-table {
  margin: auto;
  border-collapse: collapse;
}
.data-table thead th {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: var(--main-color);
}
.data-table th,
.data-table td {
  border: 1px solid #ddd;
  padding: 10px 10px;
}
.data-table th {
  /* padding-top: 12px;
  padding-bottom: 12px; */
  text-align: left;
  color: white;
}

.data-table tbody tr:nth-child(even) {
  background-color: white;
}
.data-table tbody tr:nth-child(odd) {
  background-color: white;
}
th.fixed-column {
  text-align: right;
}
.filter-input {
  color: #274a6c;
}
.filter-input {
  font-size: 14px;
  border-radius: 4px;
  border: 1px solid #ddd;
  margin-bottom: 1rem;
  width: 40%;
  font-size: 16px;
  min-width: 300px;
  padding: 10px;
  text-align: center;
}
.filter-input-container {
  width: 100%;
}
/* Headers */
.main-header,
.secondary-header {
  background-color: var(--main-color);
  padding: 1px;
  position: fixed;
  top: 0;
  z-index: 500;
  width: 100%;
}
.secondary-header {
  top: 2.5rem;
}
/* Typography */
h2 {
  margin-bottom: 0rem;
  width: 100%;
  font-size: 18px;
  padding: 0.5rem;
  border-bottom: 1px solid;
  border-top: 1px solid;
}
h3,
h4 {
  width: fit-content;
  margin: 0rem auto 2rem;
  padding: 0.5rem 3rem;
  border: solid;
  font-size: 16px;
}
h4 {
  padding: 1rem 3rem;
  background-color: var(--main-color);
  color: white;
}
/* Drop Downs */
.drop-down-1,
.drop-down-2 {
  border: none;
  padding: 5px 50px;
  width: fit-content;
  margin-left: 20px;
  text-align: center;
  border-radius: 5px;
}
.drop-down-2 {
  padding: 5px 10px;
  color: var(--main-color);
}
/* Modals */
.modal {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(31, 41, 75, 0.7);
  display: flex;
  align-items: center; /* Centrer verticalement */
  justify-content: center;
}
.modal-content {
  background-color: #fefefe;
  margin: 10rem auto;
  padding: 40px 20px 20px 20px;
  justify-content: center;
  width: 80%;
  min-height: 400px;
  max-height: 80vh;
  display: flex;
  flex-direction: column;
  text-transform: uppercase;
  z-index: 10;
  text-transform: none;
}
.table-container-modal {
  /* Définissez une hauteur maximale pour le tableau et ajoutez le défilement */
  max-height: calc(
    100vh - 120px
  ); /* Ajustez cette valeur selon la hauteur de vos autres éléments dans le modal */
  overflow-y: auto;
}
.table-container {
  overflow-y: auto;
  overflow-x: auto;
  width: 90%;
  margin: auto;
  /* padding-bottom:10rem */
}
.close {
  font-size: 50px;
  cursor: pointer;
}
/* Loading Spinner */
.loading {
  position: fixed;
  z-index: 2000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(31, 41, 75, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
}
.loading .spinner {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid var(--main-color);
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/* Fade Transition */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
/* Filter Radio */
.filter-radio {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap; /* Permet aux éléments de passer à la ligne suivante si nécessaire */
  justify-content: space-evenly;
  width: 100%;
  max-width: 1000px;
  margin: auto;
}
.filter-radio label {
  min-width: calc(15% - 10px); /* Largeur minimale pour chaque label */
  box-sizing: border-box;
  padding: 0.5rem;
}
.filters-container {
  width: 80%;
  min-width: 600px;
  margin: 50px auto 0px;
  padding: 0px 0px;
}
.filter-container {
  display: flex;
  flex-direction: column;
  /*   margin: 2rem auto; */
  justify-content: space-around;
}
.filter-buttons {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 50%;
  min-width: 1200px;
  margin: 4rem auto 0rem;
  justify-content: space-around;
}
.filter-buttons > h3 {
  flex-basis: calc(100% / 11);
  /* 4 représente le nombre d'éléments que vous souhaitez par ligne */
}
.filter-container > div {
  margin-bottom: 20px;
  /* Ajoute une marge en bas de chaque div */
}
.filter-container > div:last-child {
  margin-bottom: 0;
  /* Supprime la marge du dernier div pour éviter un espace supplémentaire en bas */
}
.filter-container2 {
  display: flex;
  /* margin: auto; */
  margin-top: 5;
  margin: 40px auto 30px;
  justify-content: space-around;
}
.success {
  font-weight: 800;
  margin: 0px 50px 50px 50px;
  font-size: 20px;
}
.data-table-modal {
  width: 50% !important;
}
@media (max-width: 1400px) and (min-width: 768px) {
  .data-table-modal {
    width: 95% !important;
  }
}
.col-15 {
  max-width: 40px;
  width: 10%;
  /*   font-size: 90%; */
}
.print-container {
  text-align: center;
  margin: 1.5rem auto;
  justify-content: center;
  display: flex;
  align-items: flex-end;
}
.updateData {
  color: white;
  background-color: #20294b;
  width: 50px;
  height: 50px;
  position: absolute;
  left: 2rem;
  top: 6rem;
  font-weight: 600;
  padding: 10px;
  border-radius: 50%;
  font-size: 12px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.col-s {
  width: 5%;
}
.col-m {
  width: 10%;
}
.col-l {
  width: 15%;
}
.col-xl {
  width: 25%;
}
.png {
  max-width: 40px;
}
.single_add_to_cart_button {
  cursor: pointer;
  text-align: left;
  width: fit-content;
  padding: 0px 5px 0 5px;
}
@media only screen and (max-width: 600px) {
  .single_add_to_cart_button {
    margin-bottom: 0px;
    /*  width: 100px; */
    text-align: center;
    display: flex;
    align-items: center;
  }
}
@media only screen and (max-width: 600px) {
  .woocommerce-variation-add-to-cart {
    width: 100px;
  }
}
.single_add_to_cart_button.disabled {
  opacity: 0.2;
}
.single_add_to_cart_button::after {
  content: "";
  display: block;
  width: 0%;
  margin: 2px auto 0 auto;
  height: 2px;
  background: #151d2c;
  font-size: 18px;
  transition: width 0.2s ease-out;
  line-height: 10px;
}
.single_add_to_cart_button:hover::after {
  width: 100%;
}
@media only screen and (max-width: 1024px) {
  .single_add_to_cart_button:hover::after {
    width: 0px;
  }
}
.calendar {
  padding: 0px 10px;
  margin: 0px 10px;
  width: max-content;
}

.left {
  text-align: left;
}

.pointer {
  cursor: pointer;
}

.router-link-exact-active {
  text-decoration: underline;
}

.lowercase {
  text-transform: lowercase;
}
</style>
