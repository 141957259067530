import { defineStore } from "pinia";
import { supabase } from "../supabase";
import { EventEmitter } from "events";

export const useStore = defineStore("main", {
  state: () => ({
    user: null,
    loggedIn: false,
    rememberMe: true,
    role: "",
    email: "",
    processingCount: 0,
    todoTaskCount: 0,
    channel: null,
    todoChannel: null,
    isSubscribed: false,
    eventEmitter: new EventEmitter(),
    personMapping: {
      "alexandra411@gmail.com": "alex",
      "orders@unenuitnomade.com": "colas",
      "cubizollesmajory@gmail.com": "majory",
      "sigmundphil@gmail.com": "philippe",
      "mehreen@unenuitnomade.com": "mehreen",
    },
  }),
  getters: {
    isLoggedIn: (state) => state.loggedIn,
    userRole: (state) => state.role,
    userEmail: (state) => state.email,
    mappedUserName: (state) => state.personMapping[state.email],
  },
  actions: {
    setUser(user) {
      this.user = user;
      this.loggedIn = true;
      this.email = user.email;
    },
    clearUser() {
      this.user = null;
      this.loggedIn = false;
      this.role = "";
      this.email = "";
    },
    setLoggedIn(loggedIn) {
      this.loggedIn = loggedIn;
    },
    setRole(role) {
      this.role = role;
    },
    setRememberMe(rememberMe) {
      this.rememberMe = rememberMe;
    },
    setTodoTaskCount(count) {
      this.todoTaskCount = count;
    },
    async login({ email, password }) {
      try {
        const { data, error } = await supabase.auth.signInWithPassword({
          email,
          password,
          options: {
            expiresIn: this.rememberMe ? 30 : undefined,
          },
        });
        if (error) {
          console.error(error);
          throw error;
        }
        this.setUser(data.user);
        let role;
        switch (email) {
          case "alexandra411@gmail.com":
          case "alexandra@unenuitnomade.com":
            role = "admin";
            break;
          case "sigmundphil@gmail.com":
            role = "admin";
            break;
          case "orders@unenuitnomade.com":
            role = "ecommerce";
            break;
          case "compta@unenuitnomade.com":
            role = "compta";
            break;
          case "cubizollesmajory@gmail.com":
          case "mehreen@unenuitnomade.com":
            role = "digital";
            break;
          default:
            role = "user";
        }
        this.setRole(role);
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async logout() {
      try {
        await supabase.auth.signOut();
        this.clearUser();
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async fetchInitialProcessingCount() {
      const { data, error } = await supabase
        .from("orders")
        .select("status")
        .eq("status", "processing");

      if (error) {
        console.error(
          "useStore :Erreur lors de la récupération des commandes en processing:",
          error,
        );
      } else {
        this.processingCount = data.length;
      }
    },

    setupRealtimeSubscription() {
      const subscribeToChannel = () => {
        if (this.isSubscribed && this.channel) {
          /*  console.log(
            "useStore :Already subscribed to orders, skipping re-subscription for orders.",
          ); */
          return;
        }

        this.channel = supabase.channel("orders");

        this.channel
          .on(
            "postgres_changes",
            { event: "*", schema: "public", table: "orders" },
            async (payload) => {
              // Émettez l'événement 'order-update' via eventEmitter
              /*  console.log(
                "useStore :Change detected in orders table:",
                payload,
              ); */
              this.eventEmitter.emit("order-update", payload);

              try {
                // Refetch the total processing count whenever there's a relevant change
                await this.fetchInitialProcessingCount();
              } catch (error) {
                console.error(
                  "useStore :Error refetching processing count:",
                  error,
                );
              }
            },
          )
          .subscribe((status) => {
            /* console.log("useStore :Header Orders Subscription status:", status); */
            if (status === "SUBSCRIBED") {
              /*  console.log(
                "useStore :Successfully subscribed to real-time updates for header orders",
              ); */
              this.isSubscribed = true;
            } else if (status === "CHANNEL_ERROR" || status === "CLOSED") {
              console.error(
                "useStore :Error subscribing to real-time updates for headers orders:",
                status,
              );
              this.isSubscribed = false;
              // Retry subscription after a delay
              setTimeout(subscribeToChannel, 5000);
            }
          });
      };

      subscribeToChannel();

      // Gérer la reconnexion en ligne
      window.addEventListener("online", () => {
        /*  console.log(
          "useStore :Internet connection restored. Checking subscription...",
        ); */
        if (!this.isSubscribed) {
          subscribeToChannel();
        }
      });

      // Gérer la perte de connexion
      window.addEventListener("offline", () => {
        /*  console.log("useStore :Internet connection lost."); */
        this.isSubscribed = false;
      });
    },
    async fetchInitialTodoTaskCount() {
      try {
        // Récupérer les données de la base de données
        const { data, error } = await supabase
          .from("events")
          .select("status, person_from, person_to")
          .eq("status", "to-do");

        if (error) {
          console.error(
            "useStore :Erreur lors de la récupération des tâches to-do:",
            error,
          );
        } else {
          const mappedUserName = this.mappedUserName;

          // Afficher les données récupérées et le nom mappé
          /*  console.log("useStore :Données brutes récupérées:", data);
          console.log(
            "useStore :Nom mappé pour l'utilisateur connecté:",
            mappedUserName,
          ); */

          // Filtrer les tâches en fonction des nouveaux critères
          const filteredTasks = data.filter(
            (task) =>
              task.person_from !== mappedUserName && // La personne connectée ne doit pas être la personne qui a créé la tâche
              task.person_to.includes(mappedUserName), // La personne connectée doit être incluse dans le tableau `person_to`
          );

          // Afficher les tâches retenues après filtrage
          /*  console.log(
            "useStore :Tâches filtrées pour le compteur:",
            filteredTasks,
          ); */

          // Mettre à jour le compteur de tâches
          this.todoTaskCount = filteredTasks.length;

          // Afficher le nouveau compteur
          /*  console.log(
            "useStore :Nouveau compteur de tâches to-do:",
            this.todoTaskCount,
          ); */
        }
      } catch (error) {
        console.error(
          "useStore :Erreur lors de la récupération des tâches to-do:",
          error,
        );
      }
    },

    // Méthode pour la souscription en temps réel des tâches to-do
    setupTodoRealtimeSubscription() {
      const subscribeToTodoChannel = () => {
        if (this.isTodoSubscribed && this.todoChannel) {
          /* console.log(
            "useStore :Already subscribed to tasks, skipping re-subscription for tasks.",
          ); */
          return;
        }

        this.todoChannel = supabase.channel("events");

        this.todoChannel
          .on(
            "postgres_changes",
            { event: "*", schema: "public", table: "events" },
            async (payload) => {
              // Émettez l'événement 'task-update' via eventEmitter
              /*  console.log("useStore :Change detected in tasks table:", payload); */
              this.eventEmitter.emit("task-update", payload);

              try {
                // Refetch the total todo task count whenever there's a relevant change
                await this.fetchInitialTodoTaskCount();
              } catch (error) {
                console.error(
                  "useStore :Error refetching todo task count:",
                  error,
                );
              }
            },
          )
          .subscribe((status) => {
            /* console.log("useStore :Tasks Subscription status:", status); */
            if (status === "SUBSCRIBED") {
              /*   console.log(
                "useStore :Successfully subscribed to real-time updates for tasks",
              ); */
              this.isTodoSubscribed = true;
            } else if (status === "CHANNEL_ERROR" || status === "CLOSED") {
              console.error(
                "useStore :Error subscribing to real-time updates for tasks:",
                status,
              );
              this.isTodoSubscribed = false;
              // Retry subscription after a delay
              setTimeout(subscribeToTodoChannel, 5000);
            }
          });
      };

      subscribeToTodoChannel();

      // Gérer la reconnexion en ligne pour les tâches
      window.addEventListener("online", () => {
        /*  console.log(
          "useStore :Internet connection restored. Checking subscription for tasks...",
        ); */
        if (!this.isTodoSubscribed) {
          subscribeToTodoChannel();
        }
      });

      // Gérer la perte de connexion pour les tâches
      window.addEventListener("offline", () => {
        /* console.log("useStore :Internet connection lost for tasks."); */
        this.isTodoSubscribed = false;
      });
    },
  },
  persist: {
    enabled: true,
    strategies: [
      {
        key: "my_store",
        storage: localStorage,
        paths: ["loggedIn", "user", "role", "email"],
      },
    ],
  },
});

// Appel de fetchInitialProcessingCount() et setupRealtimeSubscription() peut être fait dans votre composant principal ou à l'endroit où vous initialisez votre application.
