<template>
  <header class="main-header">
    <div class="header">
      <nav>
        <button class="burger-menu" @click="toggleMenu">☰</button>
        <ul :class="{ show: isMenuOpen }">
          <li
            v-if="role === 'admin' || role === 'ecommerce' || role === 'admin2'"
          >
            <router-link
              to="/order"
              :class="{
                'router-link-active':
                  $route.path === '/order' || $route.path.startsWith('/order/'),
              }"
              @click="closeMenu"
            >
              ORDER
            </router-link>
          </li>
          <li v-if="role === 'admin' || role === 'admin2'">
            <router-link
              to="/globalsales"
              :class="{
                'router-link-active':
                  $route.path === '/globalsales' ||
                  $route.path.startsWith('/globalsales/'),
              }"
              @click="closeMenu"
            >
              SALES
            </router-link>
          </li>
          <li v-if="role === 'admin' || role === 'compta' || role === 'admin2'">
            <router-link
              to="/comptabilite/cashflow"
              :class="{
                'router-link-active':
                  $route.path === '/comptabilite' ||
                  $route.path.startsWith('/comptabilite/'),
              }"
              @click="closeMenu"
            >
              COMPTA / FINANCE
            </router-link>
          </li>
          <li
            v-if="role === 'admin' || role === 'ecommerce' || role === 'admin2'"
          >
            <router-link
              to="/inventory/91"
              :class="{
                'router-link-active':
                  $route.path === '/inventory' ||
                  $route.path.startsWith('/inventory/'),
              }"
              @click="closeMenu"
            >
              INVENTORY / PRODUCTION
            </router-link>
          </li>
          <li
            v-if="role === 'admin' || role === 'ecommerce' || role === 'admin2'"
          >
            <router-link
              to="/ecommerce/orderstobeshipped"
              :class="{
                'router-link-active':
                  $route.path === '/ecommerce' ||
                  $route.path.startsWith('/ecommerce/'),
              }"
              @click="closeMenu"
            >
              ECOMMERCE ({{ processingCount }})
              <!--  <span v-if="processingCount">({{ processingCount }})</span> -->
            </router-link>
          </li>

          <li
            v-if="role === 'admin' || role === 'digital' || role === 'admin2'"
          >
            <router-link
              to="/communication/newsletter"
              :class="{
                'router-link-active':
                  $route.path === '/communication' ||
                  $route.path.startsWith('/communication/'),
              }"
              @click="closeMenu"
            >
              DIGITAL
            </router-link>
          </li>
          <li
            v-if="
              role === 'admin' ||
              role === 'ecommerce' ||
              role === 'digital' ||
              role === 'admin2'
            "
          >
            <router-link
              to="/cominterne/todolist"
              :class="{
                'router-link-active':
                  $route.path === '/cominterne' ||
                  $route.path.startsWith('/cominterne/'),
              }"
              @click="closeMenu"
            >
              TO DO LIST ({{ todoTaskCount }})
            </router-link>
          </li>
        </ul>
      </nav>
      <div class="login">
        <div class="user" v-if="user">
          {{ user.email }}
        </div>
        <div>
          <div @click="logout" class="logout">Logout</div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import { useStore } from "../../useStore";
import { computed, onMounted, watch } from "vue";
import { useRouter, useRoute } from "vue-router";

export default {
  data() {
    return {
      isMenuOpen: false,
    };
  },
  name: "HeaderComponent",
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();

    const user = computed(() => store.user);
    const role = computed(() => store.role);
    const processingCount = computed(() => store.processingCount);
    const todoTaskCount = computed(() => store.todoTaskCount);

    const logout = async () => {
      try {
        await store.logout();
        router.push("/login");
      } catch (error) {
        console.error(error);
      }
    };
    onMounted(async () => {
      // Fetch initial processing count and set up real-time subscription
      await store.fetchInitialProcessingCount();
      store.setupRealtimeSubscription();

      await store.fetchInitialTodoTaskCount();
      store.setupTodoRealtimeSubscription();
    });

    // Vérifiez si le route change et si `todoTaskCount` devient 0, rechargez-le
    watch(route, async (newRoute) => {
      // Supprimez `oldRoute` si non utilisé
      if (
        newRoute.path === "/cominterne/todolist" &&
        todoTaskCount.value === 0
      ) {
        console.log("Route change detected. Re-fetching todo tasks.");
        await store.fetchInitialTodoTaskCount();
      }
    });
    return {
      user,
      role,
      logout,
      processingCount,
      todoTaskCount,
    };
  },
  methods: {
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
    },
    closeMenu() {
      this.isMenuOpen = false;
    },
  },
};
</script>

<style scoped>
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 20px 0px 20px;
}
.login {
  display: flex;
  justify-content: FLEX-END;
  align-items: center;
  color: white;
  font-weight: bold;
}
.user {
  padding-right: 10px;
  display: none;
}
.logo {
  color: white;
  text-align: left;
  font-size: 20px;
  font-weight: bold;
  position: absolute;
}
@media only screen and (max-width: 2500px) {
  .logo {
    display: none;
  }
}
nav ul {
  list-style: none;
  padding: 0;
}
nav ul li {
  display: inline;
  padding: 0px 20px;
  background-color: var(--main-color);
}
.logout {
  color: white;
  background-color: var(--main-color);
  padding: 3px;
  cursor: pointer;
  width: fit-content;
  border-bottom: 1px solid white;
  font-weight: bold;
}

a:-webkit-any-link {
  color: -webkit-link;
  cursor: pointer;
  text-decoration: none;
  /* color: #274a6c; */
  /* color: #b5d4bf; */
  color: #efd3c9;
}

.router-link-exact-active {
  text-decoration: underline;
}
nav a.router-link-active {
  color: var(--main-color7);
  text-decoration: underline;
}

.burger-menu {
  display: none;
  background: none;
  border: none;
  font-size: 24px;
}

@media (max-width: 1200px) {
  .burger-menu {
    display: block;
  }
  nav {
    width: 10% !important;
  }
  nav ul {
    display: none;
    flex-direction: column;
    position: absolute;
    background-color: #fff;
    width: 100%;
    top: 100%; /* S'assure que le menu s'ouvre sous le header */
    left: 0;
  }
  nav ul.show {
    display: flex;
  }
  nav ul li {
    padding: 10px;
    border-bottom: 1px solid #ccc;
  }
}
</style>
